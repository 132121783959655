import { graphql } from "gatsby"
import * as React from "react"
import Layout from "../components/Layout"

export default function BlogPostTemplate({ data: { markdownRemark } }) {
  const { frontmatter, html } = markdownRemark
  return (
    <Layout>
      <section className="mt-16">
        <div className="px-8 py-20 bg-gray-50">
          <div className="max-w-screen-md mx-auto space-y-4">
            <h1 className="text-6xl font-extrabold">{frontmatter.title}</h1>
            <h2 className="text-slate/60">{frontmatter.date}</h2>
          </div>
        </div>
      </section>

      <section className="px-8 py-20">
        <div
          className="max-w-screen-md mx-auto prose"
          dangerouslySetInnerHTML={{ __html: html }}
        />
      </section>
    </Layout>
  )
}

export const pageQuery = graphql`
  query ($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        title
      }
    }
  }
`
